import Vue from 'vue';

import { waitUntil } from 'supwiz/supchat/generalUtils';
import {
  isEqual, sortBy, cloneDeep, isEmpty,
} from 'lodash';

import {
  setTenantConfig,
  getTenantConfigs,
  getTenantConfig,
  updateTenantConfig,
  getADConfig,
  updateADConfig,
  getAutoAssignConfig,
  updateAutoAssignConfig,
} from '../../../api/apiList';

const configState = {
  // tenant id and config pair (and then fetchingState)
  fetchingState: {},
};

const configGetters = {
  getConfigFull: (state) => (tenantId) => state[tenantId] || {},
  getConfigADGroups: (state, getters) => (tenantId) => getters
    .getConfigFull(tenantId).general.ad_group_ids || {},
  getConfig: (state, getters) => ({ tenantId, tag }) => getters
    .getConfigFull(tenantId)[tag] || {},
};

const mutations = {
  SET_TENANT_CONFIG_SINGLE(state, { tenantId, configs, tag }) {
    if (typeof state[tenantId] === 'undefined') Vue.set(state, tenantId, {});
    Vue.set(state[tenantId], tag, configs);
  },
  SET_TENANT_CONFIG_FULL(state, { tenantId, configs }) {
    Vue.set(state, tenantId, configs);
  },
  UPDATE_TENANT_CONFIG(state, { tenantId, tag, data }) {
    if (tag === undefined) {
      Vue.set(state[tenantId], data);
    } else {
      Vue.set(state[tenantId], tag, data);
    }
  },
  SET_TENANT_AD_CONFIG(state, { tenantId, config }) {
    if (!Object.prototype.hasOwnProperty.call(state, config)) {
      Vue.set(state, tenantId, {});
      Vue.set(state[tenantId], 'general', {});
    }
    Vue.set(state[tenantId].general, 'ad_group_ids', config);
  },
  SET_FETCHING_STATE(state, { key, isFetching }) {
    Vue.set(state.fetchingState, key, isFetching);
  },
};

const actions = {
  async ensureConfigFetched({ getters, dispatch }, { tenantId, tag }) {
    if (isEmpty(getters.getConfig({ tenantId, tag }))) {
      return dispatch('fetchAndSetConfig', { tenantId, tag });
    }
    return getters.getConfig({ tenantId, tag });
  },
  async fetchAndSetConfig({
    commit, getters, rootState, dispatch, state,
  }, { tenantId, tag }) {
    const fetchingKey = `${tenantId}${tag}` || `${tenantId}full`;
    if (state.fetchingState[fetchingKey]) {
      await waitUntil(() => !state.fetchingState[fetchingKey]);
    } else {
      try {
        let configs;
        commit('SET_FETCHING_STATE', { key: fetchingKey, isFetching: true });

        // some configs are fetched from different urls
        if (['auto_assign'].includes(tag)) {
          configs = await dispatch('fetchAndSetAutoAssignConfig', { tenantId });
        } else {
          // We're moving configs into their own tables, one tag at a time
          const movedTags = ['anonymization'];
          if (tag && movedTags.includes(tag)) {
            configs = await getTenantConfig(tenantId, tag);
          } else {
            ({ configs } = await getTenantConfigs(tenantId, tag || ''));
          }
        }
        if (tag) commit('SET_TENANT_CONFIG_SINGLE', { tenantId, configs, tag });
        else commit('SET_TENANT_CONFIG_FULL', { tenantId, configs });
      } finally {
        commit('SET_FETCHING_STATE', { key: fetchingKey, isFetching: false });
      }
    }
    // check if there's a default department
    const generalConfig = getters.getConfig({ tenantId, tag: 'general' });
    const defaultDep = generalConfig?.default_incoming_department_id || '';
    const validDep = defaultDep.includes(tenantId);
    const visitorSelectsDepartment = generalConfig?.visitor_can_select_department;
    if (
      !isEmpty(generalConfig)
      && !visitorSelectsDepartment
      && !validDep) {
      const tenantDetails = rootState.tenants.systemTenants[tenantId];
      let tenantName = null;
      if (tenantDetails) tenantName = tenantDetails.name;
      commit('errorDisplay/ADD_MSG', {
        message: ['errors.noDefaultDepartment', { tenant: tenantName || tenantId }],
        variant: 'warning',
      }, { root: true });
    }
    return tag ? getters.getConfig({ tenantId, tag }) : getters.getConfigFull(tenantId);
  },
  async ensureFullConfigOfTenantFetched({ state, dispatch }, { tenantId }) {
    if (!Object.prototype.hasOwnProperty.call(state, tenantId)
      || !isEqual(
        sortBy(Object.keys(state[tenantId])),
        sortBy([
          'anonymization',
          'general',
          'kpi',
          'opening_hours',
          'translation',
          'visitor',
        ]),
      )
    ) {
      return dispatch('fetchAndSetConfig', { tenantId });
    }
    return state[tenantId];
  },
  async updateTenantConfig({ commit }, { tenantId, tag, config }) {
    // We're moving configs into their own tables, one tag at a time
    const movedTags = ['anonymization'];
    if (movedTags.includes(tag)) {
      if (await updateTenantConfig(tenantId, tag, config)) {
        commit('UPDATE_TENANT_CONFIG', { tenantId, tag, data: config });
      }
    } else {
      /*
        We send and set xxxxx as secret to the frontend if it's been set previously.
        But we don't want to send that back because that'll break the config.
      */
      const data = cloneDeep(config);
      if (tag === 'translation') {
        if (config.lionbridge_account_secret === 'xxxxxxxxxx') {
          data.lionbridge_account_secret = undefined;
        }
        if (config.azure_subscription_key === 'xxxxxxxxxx') {
          data.azure_subscription_key = undefined;
        }
      }
      if (await setTenantConfig(tenantId, tag, data)) {
        commit('UPDATE_TENANT_CONFIG', { tenantId, tag, data: config });
      }
    }
  },
  async updateTenantConfigSingle({ commit }, { tenantId, tag, config }) {
    const data = cloneDeep(config);
    if (tag === 'translation') {
      if (config.lionbridge_account_secret === 'xxxxxxxxxx') {
        data.lionbridge_account_secret = undefined;
      }
      if (config.azure_subscription_key === 'xxxxxxxxxx') {
        data.azure_subscription_key = undefined;
      }
    }
    if (await updateTenantConfig(tenantId, tag, data)) {
      commit('UPDATE_TENANT_CONFIG', { tenantId, tag, data: config });
    }
  },
  // AD config related operation
  async fetchAndSetADConfig({ commit }, { tenantId }) {
    const data = await getADConfig(tenantId);
    commit('SET_TENANT_AD_CONFIG', { tenantId, config: data.configs });
    return data.configs;
  },
  async updateADConfig({ commit }, { tenantId, value }) {
    try {
      await updateADConfig({ tenantId, value });
      commit('SET_TENANT_AD_CONFIG', { tenantId, config: value });
    } catch (error) {
      Vue.$log.error(error);
    }
  },
  // Auto Assign config related operation
  async fetchAndSetAutoAssignConfig(amogus, { tenantId }) {
    return getAutoAssignConfig(tenantId);
  },
  async updateAutoAssignConfig({ commit }, { tenantId, configs }) {
    if (await updateAutoAssignConfig(tenantId, configs)) {
      commit('SET_TENANT_CONFIG_SINGLE', { tenantId, configs, tag: 'auto_assign' });
    }
  },
};

export default {
  state: configState,
  getters: configGetters,
  mutations,
  actions,
};
