/** Script for the agent management page */
import Vue from 'vue';
import { cloneDeep } from 'lodash';
import { waitUntil, deepFreeze } from 'supwiz/supchat/generalUtils';
import {
  addAgent,
  deleteAgent,
  getAgents,
  updateAgent,
} from '../../api/apiList';

let fetchingAgents = false;

const agentsState = {
  agents: [], // List[Object]
};

const systemAgentsGetters = {
  systemAgents: (state) => state.agents.filter((agent) => !agent.is_supwiz_user),
  agentsWithTenantLvlAccess: (state, getters) => ({ tenant }) => getters.systemAgents
    .filter((agent) => agent.roles
      .some((agentRole) => (agentRole.tenant === tenant))),
  agentsWithDepartmentLvlAccess: (state, getters) => ({ department }) => getters.systemAgents
    .filter((agent) => agent.roles
      .some((agentRole) => (agentRole.department === department))),
};

const mutations = {
  SET_AGENTS(state, systemAgents) {
    state.agents = systemAgents.map((agent) => deepFreeze({
      ...agent,
      last_active: new Date(agent.last_active).getTime() || 0,
    }));
  },

  ADD_AGENT(state, agentData) {
    state.agents.push(deepFreeze(agentData));
  },

  UPDATE_AGENT(state, { index, data }) {
    const oldAgentDetails = cloneDeep(state.agents[index]);
    Vue.set(state.agents, index, deepFreeze(Object.assign(oldAgentDetails, data)));
  },

  DELETE_AGENT(state, { index }) {
    state.agents.splice(index, 1);
  },
};

const actions = {
  async fetchAgents({ commit, state }) {
    if (fetchingAgents) {
      await waitUntil(() => !fetchingAgents);
    } else {
      fetchingAgents = true;
      const data = await getAgents();
      commit('SET_AGENTS', data.result);
      fetchingAgents = false;
    }
    return state.agents;
  },

  async ensureAgentsFetched({ state, dispatch }) {
    if (state.agents.length === 0) {
      return dispatch('fetchAgents');
    }
    return state.agents;
  },

  async add({ commit }, {
    username, password, is_bot: isBot, email,
  }) {
    try {
      const data = await addAgent({
        username, password, is_bot: isBot, email,
      });
      commit('ADD_AGENT', data);
      return data;
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'errors.failedToCreateUser',
        variant: 'danger',
      }, { root: true });
      Vue.$log.error(error);
      throw error;
    }
  },

  async update({ state, commit }, {
    id,
    display_name: displayName,
    is_bot: isBot,
    roles,
    email,
    external_uri: externalUri,
    puzzeliduser,
    hidden_pages: hiddenPages,
  }) {
    try {
      const data = await updateAgent({
        id,
        display_name: displayName,
        is_bot: isBot,
        roles,
        email,
        external_uri: externalUri,
        puzzeliduser,
        hidden_pages: hiddenPages || [],
      });
      const index = state.agents.findIndex((x) => x.id === id);
      commit('UPDATE_AGENT', { index, data });
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'errors.unknownError',
        variant: 'danger',
      }, { root: true });
      Vue.$log.error(error);
      throw error;
    }
  },

  async delete({ state, commit }, id) {
    try {
      await deleteAgent(id);
      const index = state.agents.findIndex((x) => x.id === id);
      commit('DELETE_AGENT', { index });
    } catch (error) {
      commit('errorDisplay/ADD_MSG', {
        message: 'errors.unknownError',
        variant: 'danger',
      }, { root: true });
      Vue.$log.error(error);
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state: agentsState,
  getters: systemAgentsGetters,
  mutations,
  actions,
};
